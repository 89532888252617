import React from "react"
import About from "../components/about"

const Sidebar = ({ location }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let isRootPath = false
  if (location.pathname === rootPath) {
    isRootPath = true
  }
  return (
    <aside className="content">
      <p className="mb-4 w-56 text-lg font-secondary font-bold text-gray-600">
        A collection of articles from the Abar Team.
      </p>
      <About isRootPath={isRootPath} />
    </aside>
  )
}

export default Sidebar
