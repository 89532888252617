import React from "react"

const Logo = ({ className, width, height, color }) => {
  return (
    <div>
      <svg
        alt="Abar logo"
        className={className}
        width="668"
        height="228"
        viewBox="0 0 668 228"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M110.326 3.51963C104.265 3.51913 98.3415 5.32385 93.3112 8.70344C88.281 12.083 84.3722 16.8842 82.0837 22.4942L0 223.824H52.9358L67.0569 189.201H165.221L179.342 223.834H232.601L142.773 3.51963H110.326ZM83.4145 149.099L116.139 68.6097L148.863 149.099H83.4145ZM309.538 54.5127C286.434 54.5127 267.193 64.4434 254.033 81.4596V46.8175C254.033 34.4008 249.098 22.4925 240.315 13.7125C231.531 4.93255 219.618 0 207.196 0V124.305L246.75 223.834H254.023V200.739C267.193 218.069 286.434 228 309.538 228C353.814 228 385.891 192.092 385.891 141.09C385.891 90.4203 353.814 54.5127 309.538 54.5127ZM296.054 188.564C273.283 188.564 256.592 171.88 254.023 147.51V134.697C256.592 110.679 273.274 93.9584 296.054 93.9584C322.041 93.9584 340.331 113.533 340.331 141.072C340.34 169.007 322.041 188.564 296.054 188.564ZM502.021 57.2379C469.824 50.485 438.873 56.5173 406.74 72.7944L422.451 103.908C435.611 97.1732 450.037 92.037 465.443 92.037C492.715 92.037 503.62 107.436 503.62 125.072V128.231C489.508 122.781 474.74 119.917 461.275 119.917C422.775 119.917 392.296 142.374 392.296 175.085C392.305 206.836 419.568 228 454.861 228C472.827 228 491.754 220.951 503.629 207.473V224.157H548.913V115.483C548.876 87.4827 529.441 62.9838 502.021 57.2379ZM503.666 171.88C500.459 187.279 483.446 195.621 465.489 195.621C449.446 195.621 437.57 187.307 437.57 173.82C437.57 160.333 450.989 150.402 468.021 150.402C480.166 150.36 492.226 152.423 503.666 156.499V171.88ZM611.257 89.1455V58.3556H564.402V224.166C576.826 224.166 588.741 219.233 597.527 210.451C606.312 201.669 611.247 189.759 611.247 177.339V144.952C611.247 118.827 626.884 100.674 652.409 95.5104C654.885 80.9599 660.181 67.0331 668 54.5127C642.678 54.5219 622.152 67.6767 611.257 89.1455Z" />
      </svg>
    </div>
  )
}

export default Logo
