import React from "react"
import { Link } from "gatsby"
import Logo from "../components/logo"
import Sidebar from "../components/sidebar"

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let isRootPath = false
  if (location.pathname === rootPath) {
    isRootPath = true
  }

  return (
    <div className="py-4 xl:py-8 px-8 xl:px-16">
      <Link to={`/`} className="inline-block my-4 lg:my-6 border-0">
        <h1 className="text-gray-900">
          <Logo className="w-16 lg:w-20 h-auto fill-current" />
        </h1>
      </Link>
      <div className="lg:flex items-start">
        <div
          className={`${
            !isRootPath ? "hidden" : ""
          } lg:block border-b lg:border-b-0 lg:border-r border-gray-300 pr-8 xl:pr-16 pb-6 mb-6`}
          style={{ width: "20rem" }}
        >
          <Sidebar location={location} />
        </div>
        <div className="lg:pl-8 xl:pl-16 max-w-screen-md overflow-hidden">
          <main>{children}</main>
          <footer className="mt-8 text-gray-600">
            <span className="mr-4">© {new Date().getFullYear()}</span>
            <a
              className="text-gray-600 no-underline hover:underline"
              target="_blank"
              rel="noreferrer"
              href="mailto:hello@abar.tech"
            >
              hello@abar.tech
            </a>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Layout
