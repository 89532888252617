import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Bio from "../components/bio"

const About = ({ isRootPath }) => {
  const data = useStaticQuery(graphql`
    query {
      allAuthorsJson {
        edges {
          node {
            shortName
            name
            summary
            social {
              github
              twitter
              linkedin
            }
          }
        }
      }
      allAvatar: allFile(
        filter: {
          absolutePath: { regex: "/avatars/.*(jpeg|jpg|gif|png)/" }
          sourceInstanceName: { eq: "assets" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed(width: 50, height: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const authors = data.allAuthorsJson.edges
  const avatars = data.allAvatar.edges

  return (
    <div>
      <div>
        <p className="my-0">
          Our first startup, PlanForCloud, was acquired by RightScale in 2012.
          We then built a public cloud platform that was acquired by Snapp.
          Our third startup failed. Currently working on <a href="https://www.infracost.io" target="_blank">Infracost.io</a>
        </p>
      </div>
      <div className={`${!isRootPath ? "hidden" : ""} lg:block mt-6`}>
        {authors.map(author => {
          const avatar = avatars.find(
            a => a.node.name === author.node.shortName
          )
          return (
            <Bio
              key={author.node.shortName}
              author={author.node}
              avatar={avatar.node}
            />
          )
        })}
      </div>
    </div>
  )
}

export default About
