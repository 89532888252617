import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

const Bio = ({ author, avatar }) => {
  return (
    <div className="flex items-center mb-8 last:mb-0">
      <Image
        fixed={avatar.childImageSharp.fixed}
        alt={author.name}
        style={{ minWidth: 55 }}
        className="rounded-full mr-4"
      />
      <div className="justify-start">
        <span>{author.name}</span>

        <div className="block">
          <Link
            to={`https://twitter.com/${author.social.twitter}`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            {author.social.twitter}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Bio
